import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../../services/api.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { ColaboradorService } from '../../services/colaborador.service';
import { IColaborador } from '../../models/colaborador.model';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

type option = Record<'id' | 'name', string>;

@Component({
  selector: 'app-colaborador-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSlideToggleModule,
  ],
  templateUrl: './colaborador-dialog.component.html',
  styleUrl: './colaborador-dialog.component.scss',
})
export class ColaboradorDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<ColaboradorDialogComponent>);
  readonly colaborador? = inject<IColaborador>(MAT_DIALOG_DATA);

  apiService = inject(ApiService);
  colaboradorService = inject(ColaboradorService);

  rolesList: { name: string; id: ECargo }[] = [
    { name: 'Professor(a)', id: ECargo.Professor },
    { name: 'Coodenador(a)', id: ECargo.Coordenador },
    { name: 'Administrador', id: ECargo.Adm },
  ];

  onNoClick(): void {
    this.dialogRef.close();
  }

  unidades: option[] = [];
  ngOnInit(): void {
    this.apiService
      .get<option[]>('classrooms/school-units')
      .subscribe((unidades) => {
        this.unidades = unidades;
        this.form.get('unitId')?.setValue(this.colaborador?.unitId);
      });
  }

  form: FormGroup;
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      unitId: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      active: [true, [Validators.required]],
      email: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
    });

    if (this.colaborador) this.form.patchValue(this.colaborador);
  }

  putUser() {
    //Patch the values of colaborador
    let colaborador = { ...this.colaborador, ...this.form.value };

    this.dialogRef.close();
    this.colaboradorService.put(colaborador).subscribe(() => {
      this.colaboradorService
        .getAll(this.colaboradorService.pageIndex)
        .subscribe(
          this.colaboradorService.onLoad.bind(this.colaboradorService)
        );
    });
  }

}
