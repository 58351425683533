import { Component, inject, input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { LogomarkComponent } from '../logomark/logomark.component';
import { MatSidenav } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  NavigationExtras,
  Router,
  RouterModule,
} from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutoFocusDirective } from '../../directives/auto-focus.directive';
import { NotificationService } from '../../services/notification.service';
import { MobileQueryService } from '../../services/mobile-query.service';
import { NotificationsComponent } from '../../views/notifications/notifications.component';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    LogomarkComponent,
    RouterModule,
    NgTemplateOutlet,
    FormsModule,
    AutoFocusDirective,
    NotificationsComponent
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit, OnDestroy {
  protected authService = inject(AuthService);
  private router = inject(Router);

  private route = inject(ActivatedRoute);
  protected notificationService = inject(NotificationService);

  autofocus: boolean = false;
  search: string = '';
  searchSubscription?: Subscription;

  showSearchInputOnMobile = !!this.route.snapshot.queryParams['search'];
  snav = input.required<MatSidenav>();

  firstName() {
    return this.authService.currentUser.name?.split(' ')[0];
  }

  ngOnInit(): void {
    if (!this.searchSubscription)
      this.searchSubscription = this.route.queryParams.subscribe((params) => {
        this.search = params['search'];
        this.showSearchInputOnMobile = !!this.search;
      });
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
  }

  onSearch() {
    let navigationExtras: NavigationExtras = {};

    if (this.search) navigationExtras.queryParams = { search: this.search };

    this.router.navigate([''], navigationExtras);
  }
}
