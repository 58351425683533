<h2 mat-dialog-title style="margin-left: -0.5rem;">
    Aulas Ministradas
</h2>

<mat-dialog-content>
    <table mat-table [dataSource]="timeSheetRecords" class="table !shadow-none">

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Data </th>
        <td mat-cell *matCellDef="let class"> <p class="text-nowrap">{{formatDate(class.date)}}</p> </td>
    </ng-container>

    <ng-container matColumnDef="teacher">
        <th mat-header-cell *matHeaderCellDef> Teacher </th>
        <td mat-cell *matCellDef="let class"> <p class="text-nowrap">{{class.teacher}}</p> </td>
    </ng-container>

    <ng-container matColumnDef="classroomName">
        <th mat-header-cell *matHeaderCellDef> Turma </th>
        <td mat-cell *matCellDef="let class"> <p class="text-nowrap">{{class.classroomName}}</p> </td>
    </ng-container>

    <ng-container matColumnDef="lastWord">
        <th mat-header-cell *matHeaderCellDef> Last Word </th>
        <td mat-cell *matCellDef="let class"> <p class="text-nowrap">{{class.lastWord}}</p> </td>
    </ng-container>

    <ng-container matColumnDef="paragraph">
        <th mat-header-cell *matHeaderCellDef> Paragraph </th>
        <td mat-cell *matCellDef="let class"> {{class.paragraph ?? '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="dictation">
        <th mat-header-cell *matHeaderCellDef> Dictation </th>
        <td mat-cell *matCellDef="let class"> {{class.dictation ?? '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="reading">
        <th mat-header-cell *matHeaderCellDef> Reading </th>
        <td mat-cell *matCellDef="let class"> {{class.reading ?? '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="stageId">
        <th mat-header-cell *matHeaderCellDef> Stage </th>
        <td mat-cell *matCellDef="let class"> {{class.stageId}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let class; columns: displayedColumns;"></tr>
</table>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</mat-dialog-actions>