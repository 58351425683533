import { inject, Injectable } from '@angular/core';
import { IPaginatedResult } from '../../models/paginatedResult.model';
import { studentRiskMap } from './mapa-de-risco.component';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class MapaDeRiscoService {

  private apiService = inject(ApiService);
  public dataSource = new MatTableDataSource<studentRiskMap>([]);
  get isLoading() {
    return this._isLoading;
  }

  _isLoading: boolean = false;
  totalItems: number = 0;
  pageIndex: number = 0;
  notFound: boolean = false;

  onLoad(data: IPaginatedResult<studentRiskMap>) {
    this.dataSource.data = data.items;
    this.totalItems = data.total;
    this.notFound = data.total === 0;
  }

  getAll(pageIndex: number = 0, pageSize: number = 8, nameFilter: string = '') {
    this.pageIndex = pageIndex;

    this._isLoading = true;
    this.notFound = false;

    let params: any = {
      pageIndex,
      pageSize,
    };

    if (nameFilter) params.nameFilter = nameFilter;

    return this.apiService
      .getByFilters<IPaginatedResult<studentRiskMap>>('students/mapa_de_risco', params)
      .pipe(
        finalize(() => {
          this._isLoading = false;
        })
      );
  }
}
