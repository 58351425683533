<ng-template #assessment let-student="student">
    <div class="flex flex-row justify-between md:justify-around text-center">
        @if(assessmentsByStudent){
        @let readonly = weeklyAssessmentComplete === true || weeklyAssessmentComplete == undefined;
        <div>
            <p class="text-light !mb-1">Writing</p>
            <app-stars [grade]="assessmentsByStudent[student.id]?.writing" [readonly]="readonly"
                (change)="student.writing = $event"></app-stars>
        </div>
        <div>
            <p class="text-light !mb-1">Speaking</p>
            <app-stars [grade]="assessmentsByStudent[student.id]?.speaking" [readonly]="readonly"
                (change)="student.speaking = $event"></app-stars>
        </div>
        <div>
            <p class="text-light !mb-1">Reading</p>
            <app-stars [grade]="assessmentsByStudent[student.id]?.reading" [readonly]="readonly"
                (change)="student.reading = $event"></app-stars>
        </div>
        }
        @else {
        <div>
            <p class="text-light !mb-1">Writing</p>
            <app-stars></app-stars>
        </div>
        <div>
            <p class="text-light !mb-1">Speaking</p>
            <app-stars></app-stars>
        </div>
        <div>
            <p class="text-light !mb-1">Reading</p>
            <app-stars></app-stars>
        </div>
        }
    </div>
</ng-template>

<ng-template #attendance let-presenca="presenca">
    <div>
        <p class="text-center pr-1 slide-in">
            <span class="text-light">
                Presença
            </span>
            <br>
            <span [style.color]="getPresencaColor(presenca)" style="font-weight: 500;">
                {{presenca}}%
            </span>
        </p>
    </div>
</ng-template>


<div class="card">
    <div class="flex flex-row justify-between items-center border-gray-300 gap-2 p-4">

        <div class="flex flex-row items-center gap-2">

            <h6 class="text-left">Alunos</h6>

            @if(authService.hasRole([authService.roles.Adm, authService.roles.Coordenador])){
            <button mat-icon-button class="!overflow-hidden !rounded-full float-right">
                <mat-icon>person_add</mat-icon>
            </button>
            }
        </div>

        @switch (weeklyAssessmentComplete) {
        @case(true){
        <p class="!text-sm cursor-pointer" style="color: var(--green);" (click)="openAssessmentDialog()">
            <mat-icon [inline]="true" class="align-middle">check_circle</mat-icon>
            Avaliação <span class="max-md:hidden">Semanal</span> Concluída
        </p>
        }
        @case (false){
        <p class="!text-sm cursor-pointer" style="color: var(--red);" (click)="openAssessmentDialog()">
            <mat-icon [inline]="true" class="align-middle">info</mat-icon>
            Avaliação <span class="max-md:hidden">Semanal</span> Pendente
        </p>
        }
        }

    </div>
    <table mat-table [dataSource]="students()" class="table md-border-0 !shadow-none !rounded-none" id="students-table">

        <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef class="hidden"></th>
            <td mat-cell *matCellDef="let student">

                <div class="flex flex-col py-6 gap-4" appHoldable (held)="openStudentBottomSheet(student)">

                    <div class="flex flex-row gap-4 max-md:justify-between">
                        <div class="flex flex-row gap-4 md:w-2/3">
                            <div class="w-12">
                                <img [src]="'assets/people/' + student.gender + '/person-' + student.avatarId + '.jpeg'"
                                    alt="Foto do aluno"
                                    class="w-full rounded-full aspect-square object-cover object-center">
                            </div>
                            <div>
                                <p
                                    class="!mb-0 font-medium w-full overflow-hidden whitespace-nowrap text-ellipsis truncate">
                                    <span class="md:hidden">
                                        {{getShortName(student.name)}}
                                    </span>
                                    <span class="max-md:hidden">
                                        {{student.name}}
                                    </span>
                                </p>
                                <p class="!mb-0 text-light">{{student.age}} anos</p>
                            </div>
                        </div>
                        @let presenca = attendances()[student.id];
                        @if(presenca >= 0){
                        <ng-container *ngTemplateOutlet="attendance; context: {presenca}"></ng-container>
                        }
                    </div>

                    <div class="md:hidden">
                        <ng-container *ngTemplateOutlet="assessment; context: {student}"></ng-container>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="assessment">
            <th mat-header-cell *matHeaderCellDef class="hidden"></th>
            <td mat-cell *matCellDef="let student" class="max-md:hidden">
                <ng-container *ngTemplateOutlet="assessment; context: {student}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="hidden"></th>
            <td mat-cell *matCellDef="let student" class="max-md:hidden">
                <button mat-icon-button class="!overflow-hidden !rounded-full float-right"
                    (click)="openStudentBottomSheet(student)">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="!cursor-default"></tr>
    </table>

    @if (weeklyAssessmentComplete == false) {
    <div class="flex flex-row justify-center md:justify-end p-4 gap-4">
        <button mat-flat-button (click)="saveAssessment()" [disabled]="apiService.isLoading" class="max-md:w-full">
            <mat-icon class="mat-18">send</mat-icon>
            Enviar avaliação semanal
        </button>
    </div>
    }

</div>