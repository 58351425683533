<div class="flex flex-col gap-4 py-4">

  <div class="flex flex-row gap-4">
    <div class="w-20">
      <img [src]="'assets/people/' + data.student.gender + '/person-' + data.student.avatarId + '.jpeg'" alt="Foto do aluno"
        class="w-full rounded-full aspect-square object-cover object-center">
    </div>
    <div>
      <p class="!mb-0 font-medium w-full overflow-hidden whitespace-nowrap text-ellipsis truncate">
        {{data.student.name}}
      </p>
      <p class="!mb-0 text-light">{{data.student.age}} anos</p>
    </div>
  </div>

  <button mat-flat-button color="warn" (click)="onRemoval()" [disabled]="!canRemove()">
    <mat-icon class="mat-18">person_remove</mat-icon>
    Remover da turma
  </button>
</div>