<div class="flex flex-row items-center gap-4">
    <img [src]="picUrl" alt="Profile Picture" class="w-12 rounded-full">
    <div class="flex flex-col">

        @let name = user().name.toLowerCase();
        
        @if(shortenName()){
            <p class="font-medium text-nowrap capitalize">{{getShortName(name)}}</p>
        }
        @else {
            <p class="font-medium capitalize">{{name}}</p>
        }

        <ng-content></ng-content>
    </div>
    
</div>