import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { getShortName } from '../../models/colaborador.model';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { StarsComponent } from '../../components/stars/stars.component';
import { MessageService } from '../../services/message.service';
import { MobileQueryService } from '../../services/mobile-query.service';
import { LogomarkComponent } from '../../components/logomark/logomark.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { AvaliacoesService } from './avaliacoes.service';

@Component({
  selector: 'app-avalicaoes',
  standalone: true,
  imports: [
    MatTableModule,
    MatIconModule,
    MatSlideToggleModule,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ProfilePictureComponent,
    StarsComponent,
    LogomarkComponent,
    LoadingComponent,
  ],
  templateUrl: './avalicaoes.component.html',
  styleUrl: './avalicaoes.component.scss',
})
export class AvalicaoesComponent implements OnInit {
  protected messageService = inject(MessageService);
  protected mobileQueryService = inject(MobileQueryService);
  protected avaliacoesService = inject(AvaliacoesService);

  displayedColumns: string[] = ['nome', 'uploads', 'grade'];

  getShortName = getShortName;

  ngOnInit(): void {
    this.avaliacoesService.getAll().subscribe();
  }
}
