import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ITurma } from '../models/turma.model';
import { IPaginatedResult } from '../models/paginatedResult.model';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs';
import { IAluno } from '../models/aluno.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TurmaService {
  private apiService = inject(ApiService);
  public turmas = new MatTableDataSource<ITurma>([]);
  private route = inject(ActivatedRoute);

  public students: IAluno[] = [];

  _isLoading: boolean = false;
  totalItems: number = 0;
  pageIndex: number = 0;
  notFound: boolean = false;

  get isLoading() {
    return this._isLoading;
  }

  put(classroom: Partial<ITurma>) {
    this._isLoading = true;
    this.apiService.post('classrooms/', classroom).subscribe({
      next: () => {
        this._isLoading = false;
        setTimeout(() => {

          const nameFilter = this.route.snapshot.queryParams['search'];

          this.getAll(undefined, undefined, nameFilter).subscribe(this.onLoad.bind(this));
        }, 0);
      },
      error: () => {
        this._isLoading = false;
      },
    });
  }

  onLoad(data: IPaginatedResult<ITurma>) {
    this.turmas.data = data.items;
    this.totalItems = data.total;
    this.notFound = data.total === 0;
  }

  getAll(pageIndex: number = 0, pageSize: number = 8, nameFilter: string = '') {
    this.pageIndex = pageIndex;

    this._isLoading = true;
    this.notFound = false;

    let params: any = {
      pageIndex,
      pageSize,
    };

    if (nameFilter) params.nameFilter = nameFilter;

    return this.apiService
      .getByFilters<IPaginatedResult<ITurma>>('classrooms/', params)
      .pipe(
        finalize(() => {
          this._isLoading = false;
        })
      );
  }
}
