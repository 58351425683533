<div class="flex flex-col">

    @for (notification of notificationService.notifications(); track notification){
        <button mat-menu-item>
            <mat-icon>info_outline</mat-icon>
            <p class="font-medium">{{notification.title}}</p>
            <span class="font-normal text-light">{{notification.message}}</span>
        </button>
    }
    @empty {
        <button mat-menu-item>
            <mat-icon>info_outline</mat-icon>
            <span class="font-normal text-light">Você não tem notificações</span>
        </button>
    }
</div>