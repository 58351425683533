@if(avaliacoesService.dataSource.data.length === 0){
<div class="h-full flex flex-col justify-center items-center gap-5 text-light">
  <app-logomark [theme]="'dark'" class="md:hidden"></app-logomark>
  <app-loading></app-loading>
</div>
}
@else{
<table mat-table [dataSource]="avaliacoesService.dataSource" class="table md-border-0" id="assessments-table">

  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef>
      @if(mobileQueryService.matches){
      <mat-icon class="mat-18">hotel_class</mat-icon>
      Avaliações Docentes
      }@else{
      <mat-icon class="mat-18">person</mat-icon>
      Professor
      }
    </th>
    <td mat-cell *matCellDef="let colaborador">
      <div class="py-4">
        <app-profile-picture [user]="colaborador" [shortenName]="mobileQueryService.matches">
          @if(mobileQueryService.matches){
          <div class="flex flex-col gap-x-4 max-md:!text-xs">
            <div class="flex flex-row gap-2">
              <span class="text-light text-nowrap">New Work</span>
              @if(colaborador.new_work_uploaded){
              <mat-icon class="text-green-600" [inline]="true">check</mat-icon>
              }@else{
              <mat-icon class="text-red-600" [inline]="true">close</mat-icon>
              }
            </div>
            <div class="flex flex-row gap-2">
              <span class="text-light">Revision</span>
              @if(colaborador.revision_uploaded){
              <mat-icon class="text-green-600" [inline]="true">check</mat-icon>
              }@else{
              <mat-icon class="text-red-600" [inline]="true">close</mat-icon>
              }
            </div>
          </div>
          }
        </app-profile-picture>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="uploads">
    <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
      <mat-icon class="mat-18">videocam</mat-icon>
      Uploads
    </th>
    <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">

      <div class="grid grid-cols-2 max-w-fit gap-x-4">
        <span class="text-light">Revision</span>
        @if(colaborador.revision_uploaded){
        <mat-icon class="text-green-600">check</mat-icon>
        }@else{
        <mat-icon class="text-red-600">close</mat-icon>
        }
        <span class="text-light">New Work</span>
        @if(colaborador.new_work_uploaded){
        <mat-icon class="text-green-600">check</mat-icon>
        }@else{
        <mat-icon class="text-red-600">close</mat-icon>
        }
      </div>

    </td>
  </ng-container>

  <ng-container matColumnDef="grade">
    <th mat-header-cell *matHeaderCellDef class="!text-center">
      <div class="max-md:hidden">
        Avaliação
      </div>
    </th>
    <td mat-cell *matCellDef="let colaborador" class="!text-center">

      @if(colaborador.score) {
      <a class="flex flex-row items-center gap-2 justify-center" [routerLink]="['teacher', colaborador.id]">
        <p style="color: var(--orange);">
          {{colaborador.score}}%
        </p>
        <app-stars [readonly]="true" [grade]="colaborador.score"></app-stars>
      </a>
      }@else {
      <a mat-flat-button [routerLink]="['teacher', colaborador.id]"
        [disabled]="!colaborador.new_work_uploaded || !colaborador.revision_uploaded">
        <mat-icon>edit</mat-icon>
        Avaliar
      </a>
      }

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let colaborador; columns: displayedColumns;"></tr>
</table>

}