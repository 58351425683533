import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MapaDeRiscoService } from '../views/mapa-de-risco/mapa-de-risco.service';
import { Subscription, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Directive()
export abstract class BasePaginatedComponent
  implements AfterViewInit, OnDestroy
{
  protected mapaDeRiscoService = inject(MapaDeRiscoService);

  private route = inject(ActivatedRoute);
  protected searchFilter?: string;
  private routeSubscription?: Subscription;

  private cd = inject(ChangeDetectorRef);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.paginator.pageSize = 8;

    this.paginator.page
      .pipe(switchMap(() => this.loadData()))
      .subscribe((response) => {
        this.mapaDeRiscoService.onLoad(response);
        this.setUpPageSizeAndLength();
      });

    if (!this.routeSubscription) {
      this.routeSubscription = this.route.queryParams.subscribe((params) => {
        this.searchFilter = params['search'];
        this.loadData(params['search']).subscribe((response) => {
          this.mapaDeRiscoService.onLoad(response);
          this.setUpPageSizeAndLength();
        });
      });
    }

    this.cd.detectChanges();
  }

  loadData(nameFilter?: string) {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    return this.mapaDeRiscoService.getAll(pageIndex, pageSize, nameFilter);
  }

  setUpPageSizeAndLength() {
    this.paginator.length = this.mapaDeRiscoService.totalItems;
    this.paginator.pageIndex = this.mapaDeRiscoService.pageIndex;
  }
}
