import { Component, input, OnInit } from '@angular/core';
import { getShortName, IColaborador } from '../../models/colaborador.model';

@Component({
  selector: 'app-profile-picture',
  standalone: true,
  imports: [],
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.scss',
})
export class ProfilePictureComponent implements OnInit {
  user = input.required<IColaborador>();

  shortenName = input<boolean>(false);

  picUrl: string = '';

  ngOnInit() {
    this.picUrl =
      '/assets/people/' +
      this.user().gender +
      '/person-' +
      this.user().avatarId +
      '.jpeg';
  }

  getShortName = getShortName;
}
