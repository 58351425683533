@if(turmaService.turmas.data.length === 0) {
@if(turmaService.notFound){

<div class="h-full flex flex-col justify-center items-center gap-5 text-light">
  <app-not-found message="Oops, não encontramos nada"></app-not-found>
</div>
}
@else {
<div class="h-full flex flex-col justify-center items-center gap-5 text-light">
  <app-logomark [theme]="'dark'" class="md:hidden"></app-logomark>
  <app-loading></app-loading>
</div>
}
}
@else {

@if(!searchFilter){

<app-teacher-dashboard></app-teacher-dashboard>

}
<div class="w-full relative">
  <div
    class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
    [ngClass]="{'hidden': !turmaService.isLoading, 'flex': turmaService.isLoading}">
    <app-loading [shape]="'circle'"></app-loading>
  </div>

  <table mat-table [dataSource]="turmaService.turmas" class="table">

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>
        <mat-icon>school</mat-icon>
        <span class="max-md:hidden">Turma</span>
        <span class="md:hidden">Turmas</span>
      </th>
      <td mat-cell *matCellDef="let turma" [routerLink]="['/turma', turma.id]"> {{turma.name}} </td>
    </ng-container>

    <ng-container matColumnDef="unidade">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>place_on</mat-icon>
        Unidade
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> {{turma.unit}}
      </td>
    </ng-container>

    <ng-container matColumnDef="horario">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>schedule</mat-icon>
        Horario
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> 
        {{turma.timeslot}}
      </td>
    </ng-container>

    <ng-container matColumnDef="teacher">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>person</mat-icon>
        Teacher
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> 
        {{getShortName(turma.teacherName)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="estagio">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>menu_book</mat-icon>
        Estágio
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]">
        {{turma.stage ?? '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="formato">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>domain</mat-icon>
        Formato
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> {{turma.format}}
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        Status
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]">
        <chip [color]="getTurmaStatusColor(turma.statusId)">
          {{enumToString(eTurmaStatus, turma.statusId)}}
        </chip>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'hidden': !canEdit()}"></th>
      <td mat-cell *matCellDef="let turma" [ngClass]="{'hidden': !canEdit()}">

        <button mat-icon-button class="!overflow-hidden !rounded-full float-right" [matMenuTriggerFor]="options">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #options="matMenu">
          <button mat-menu-item (click)="edit(turma)">
            <mat-icon>edit</mat-icon>
            <span class="font-normal text-light">Editar</span>
          </button>
        </mat-menu>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let turma; columns: displayedColumns;"></tr>
  </table>
</div>
}

<div [hidden]="turmaService.turmas.data.length === 0 || isTeacher()" class="mt-5">
  <mat-paginator [pageSize]="isTeacher() ? 100 : 8" [hidePageSize]="true" [pageIndex]="turmaService.pageIndex"
    [length]="turmaService.totalItems" [showFirstLastButtons]="true">
  </mat-paginator>
</div>