import { Component, inject, input, model, OnInit, output } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { IAluno } from '../../models/aluno.model';
import { StarsComponent } from '../stars/stars.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { NgTemplateOutlet } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { MessageService } from '../../services/message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StudentBottomSheetComponent } from '../student-bottom-sheet/student-bottom-sheet.component';
import { HoldableDirective } from '../../directives/holdable.directive';
import { getShortName } from '../../models/colaborador.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-students-table',
  standalone: true,
  imports: [
    MatTableModule,
    StarsComponent,
    MatIconModule,
    MatButtonModule,
    NgTemplateOutlet,
    HoldableDirective,
  ],
  templateUrl: './students-table.component.html',
  styleUrl: './students-table.component.scss',
})
export class StudentsTableComponent implements OnInit {
  private route = inject(ActivatedRoute);
  protected apiService = inject(ApiService);
  private _snackBar = inject(MatSnackBar);
  readonly dialog = inject(MatDialog);
  private messageService = inject(MessageService);
  private _bottomSheet = inject(MatBottomSheet);

  protected authService = inject(AuthService);

  private readonly assessmentExplanation =
    '1 estrela significa "poor", 2 estrelas "average" e 3 estrelas "good".';

  students = model.required<IAluno[]>();
  attendances = input.required<{ [key: string]: number }>();

  onGetAssessmentPercentage = output<number>();

  classroomId: string = '';
  assessmentsByStudent?: AssessmentsByStudent;
  weeklyAssessmentComplete?: boolean;
  displayedColumns: string[] = ['nome', 'assessment', 'actions'];

  openStudentBottomSheet(student: IAluno): void {
    this._bottomSheet.open(StudentBottomSheetComponent, {
      data: { student, classroomId: this.classroomId },
    }).afterDismissed().subscribe((removalSubscription: Observable<string | undefined> | undefined)=>{
      if(!removalSubscription)
        return

      removalSubscription.subscribe(studentId=>{
        if(studentId)
          this.students.set(this.students().filter(s=>s.id!=studentId))
      })
    });
  }

  getPresencaColor(presenca: number) {
    return 'var(--' + (presenca > 50 ? 'green' : 'red') + ')';
  }

  ngOnInit(): void {
    this.classroomId = this.route.snapshot.params['id'];

    this.apiService
      .get<{ assessments: AssessmentsByStudent; classroomPercentage: number }>(
        `assessments/classrooms/${this.classroomId}/current_week`
      )
      .subscribe((response) => {
        this.assessmentsByStudent = response.assessments;
        this.onGetAssessmentPercentage.emit(response.classroomPercentage);

        if (this.students().length > 0)
          this.weeklyAssessmentComplete =
            Object.keys(response.assessments).length > 0;
      });
  }

  openAssessmentDialog() {
    if (this.weeklyAssessmentComplete) {
      this.messageService.alert({
        title: 'Avaliação Concluída',
        message: `Você poderá avaliar novamente os alunos na próxima semana. ${this.assessmentExplanation}`,
      });
    } else {
      this.messageService.alert({
        title: 'Avaliação Pendente',
        message: `Clique nas estrelas para avaliar os alunos. ${this.assessmentExplanation}`,
      });
    }
  }

  getShortName = getShortName;

  saveAssessment() {
    let payload: AssessmentPayload = {
      classroom_id: this.classroomId,
      assessments: [],
    };

    const students = this.students();

    for (let i = 0; i < students.length; i++) {
      const student = students[i];
      const speaking = student.speaking ?? 0;
      const writing = student.writing ?? 0;
      const reading = student.reading ?? 0;

      if (!speaking || !writing || !reading) {
        this.messageService.alert(
          `Você deve dar no mínimo uma estrela em cada habilidade para todos os alunos. 
          ${this.assessmentExplanation} Encontramos o seguinte aluno com habilidade não avaliada: ${student.name}`
        );
        return;
      }

      payload.assessments.push({
        student_id: student.id,
        speaking,
        writing,
        reading,
      });
    }

    this.apiService.post('assessments/', payload).subscribe({
      next: () => {
        this.weeklyAssessmentComplete = true;
        this.messageService.celebrate();
        this._snackBar.open('Avaliação semanal enviada!', 'ok');
      },
    });
  }
}

type AssessmentPayload = {
  classroom_id: string;
  assessments: Assessment[];
};

type Assessment = {
  student_id: string;
  speaking: number;
  writing: number;
  reading: number;
};

type AssessmentsByStudent = {
  [studentId: string]: Assessment | undefined;
};
