import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StatisticComponent } from '../statistic/statistic.component';
import { MatIconModule } from '@angular/material/icon';
import { LiveBoardComponent } from '../live-board/live-board.component';
import { TimesheetDialogComponent } from '../timesheet-dialog/timesheet-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { forkJoin, Observable, tap } from 'rxjs';
import { LoadingComponent } from '../loading/loading.component';
import {
  UploadVideoDialogComponent,
  VideoType,
} from '../upload-video-dialog/upload-video-dialog.component';
import { MessageService } from '../../services/message.service';
import { AssessmentDialogComponent } from '../assessment-dialog/assessment-dialog.component';
import { AuthService } from '../../services/auth.service';
import { AvaliacoesService } from '../../views/avalicaoes/avaliacoes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teacher-dashboard',
  standalone: true,
  imports: [
    StatisticComponent,
    MatIconModule,
    LiveBoardComponent,
    MatButtonModule,
    LoadingComponent,
  ],
  templateUrl: './teacher-dashboard.component.html',
  styleUrl: './teacher-dashboard.component.scss',
})
export class TeacherDashboardComponent implements OnInit {
  private apiService = inject(ApiService);
  private messageService = inject(MessageService);
  protected avaliacoesService = inject(AvaliacoesService);
  protected authService = inject(AuthService);
  protected router = inject(Router);

  protected weeklyAssessmentsComplete?: number;
  protected totalClassrooms?: number;

  ClassroomAssessmentStatuses?: ClassroomAssessmentStatus[];

  newWorkUploaded?: number;
  revisionUploaded?: number;

  expectedUploads?: number;

  readonly dialog = inject(MatDialog);
  readonly videoTypes = VideoType;

  protected liveBoardMessages: string[] = [];
  @ViewChild(LiveBoardComponent) liveBoard!: LiveBoardComponent;

  timesheet?: {
    currentMonth: string;
    totalClasses: number;
    diaryList: Array<any>;
  };

  ngOnInit(): void {
    this.loadDashboardData();
  }

  loadDashboardData() {
    forkJoin({
      timesheet: this.getTimesheet(),
      assessmentsStatus: this.getAssessmentsStatus(),
      uploadStatus: this.getVideosUploadStatus(),
    }).subscribe(() => {
      // Only execute this after the first two methods complete
      this.getLiveBoardMessages();
    });
  }

  getTimesheet() {
    return this.apiService.get<typeof this.timesheet>('diario/timesheet').pipe(
      tap((timesheet) => {
        this.timesheet = timesheet;
      })
    );
  }

  getAssessmentsStatus() {
    return this.apiService
      .get<ClassroomAssessmentStatus[]>('assessments/status')
      .pipe(
        tap((classrooms) => {
          this.weeklyAssessmentsComplete = classrooms.filter(
            (classroom) => classroom.weeklyAssessmentsComplete
          ).length;
          this.totalClassrooms = classrooms.length;

          this.ClassroomAssessmentStatuses = classrooms;
        })
      );
  }

  getLiveBoardMessages() {
    this.liveBoardMessages = [];

    if (this.weeklyAssessmentsComplete && (this.weeklyAssessmentsComplete === this.totalClassrooms)) {
      this.liveBoardMessages.push(
        `<span class='font-medium'>Todas as avaliações estão completas</span> 🎉 <span class='text-light'>Ótimo trabalho!</span>`
      );
    } else if (this.totalClassrooms && this.weeklyAssessmentsComplete) {
      const pendingAssessments =
        this.totalClassrooms - this.weeklyAssessmentsComplete;

      this.liveBoardMessages.push(
        `<span class='font-medium'>Ainda há ${pendingAssessments} ${
          pendingAssessments == 1 ? 'avaliação' : 'avaliações'
        }</span> ⏳ 
         <span class='text-light'>para completar nessa semana</span>`
      );
    }

    switch (this.revisionUploaded) {
      case 0:
        if (this.authService.hasRole(this.authService.roles.Professor))
          this.liveBoardMessages.push(
            `<span class='font-medium'>Você ainda não enviou o video</span> 🚨 <span class='text-light'>de Revision do mês</span>`
          );
        else
          this.liveBoardMessages.push(
            `<span class='font-medium'>Nenhum vídeo de Revision foi enviado</span> 🚨 <span class='text-light'>nesse mês</span>`
          );
        break;

      case this.expectedUploads:
        if (this.authService.hasRole(this.authService.roles.Professor))
          this.liveBoardMessages.push(
            `<span class='font-medium'>Vídeo de Revision do mês enviado </span> 🏆 <span class='text-light'>mandou bem!</span>`
          );
          else
          this.liveBoardMessages.push(
            `<span class='font-medium'>Todos os vídeos de Revision foram enviados </span> 🏆 <span class='text-light'>mandou bem!</span>`
          );
        break;

      case 1:
        this.liveBoardMessages.push(
          `<span class='font-medium'>Apenas 1 vídeo de Revision foi enviado </span> 🧐 <span class='text-light'>até o momento</span>`
        );
        break;

      default:
        if (this.authService.hasRole([this.authService.roles.Adm, this.authService.roles.Coordenador]))
          this.liveBoardMessages.push(
            `<span class='font-medium'>${this.revisionUploaded} vídeos de Revision foram enviados </span> 🧐 <span class='text-light'>até o momento</span>`
          );
    }

    switch (this.newWorkUploaded) {
      case 0:
        if (this.authService.hasRole(this.authService.roles.Professor))
          this.liveBoardMessages.push(
            `<span class='font-medium'>Você ainda não enviou o video</span> 😢 <span class='text-light'>de New Work do mês</span>`
          );
        else
          this.liveBoardMessages.push(
            `<span class='font-medium'>Nenhum vídeo de New Work foi enviado</span> 😢 <span class='text-light'>nesse mês</span>`
          );
        break;

      case this.expectedUploads:
        if (this.authService.hasRole(this.authService.roles.Professor))
          this.liveBoardMessages.push(
            `<span class='font-medium'>Vídeo de New Work do mês enviado </span> 🤩 <span class='text-light'>mandou bem!</span>`
          );
          else
          this.liveBoardMessages.push(
            `<span class='font-medium'>Todos os vídeos de New Work foram enviados </span> 🤩 <span class='text-light'>mandou bem!</span>`
          );
        break;

      case 1:
        this.liveBoardMessages.push(
          `<span class='font-medium'>Apenas 1 vídeo de New Work foi enviado </span> 📋 <span class='text-light'>até o momento</span>`
        );
        break;

      default:
        if (this.authService.hasRole([this.authService.roles.Adm, this.authService.roles.Coordenador]))
          this.liveBoardMessages.push(
            `<span class='font-medium'>${this.newWorkUploaded} vídeos de New Work foram enviados </span> 📋 <span class='text-light'>até o momento</span>`
          );
    }

    this.liveBoardMessages;
  }

  getVideosUploadStatus(): Observable<any> {
    if (this.authService.hasRole(this.authService.roles.Professor)) {
      return this.apiService
        .get('assessments/teachers/check_upload_status')
        .pipe(
          tap((response) => {
            this.expectedUploads = 1;
            this.newWorkUploaded = response.new_work_uploaded ? 1 : 0;
            this.revisionUploaded = response.revision_uploaded ? 1 : 0;
          })
        );
    } else {
      return this.avaliacoesService.getAll().pipe(
        tap((teachers) => {
          this.expectedUploads = teachers.length;
          this.newWorkUploaded = teachers.filter(
            (t) => t.new_work_uploaded
          ).length;
          this.revisionUploaded = teachers.filter(
            (t) => t.revision_uploaded
          ).length;
        })
      );
    }
  }

  openTimesheetDialog(): void {
    this.dialog.open(TimesheetDialogComponent, {
      data: this.timesheet?.diaryList,
    });
  }

  openVideoDialog(videoType: VideoType): void {
    if (
      this.authService.hasRole([
        this.authService.roles.Adm,
        this.authService.roles.Coordenador,
      ])
    ) {
      this.router.navigate(['avaliacoes']);
      return;
    }

    if (videoType == VideoType.NEW_WORK && this.newWorkUploaded) {
      this.messageService.alert({
        title: 'New Work',
        message:
          'O vídeo do New Work já foi enviado. Agora é só aguardar pelo seu feedback mensal!',
      });
      return;
    }

    if (videoType == VideoType.REVISION && this.revisionUploaded) {
      this.messageService.alert({
        title: 'Revision',
        message:
          'O vídeo de Revision já foi enviado. Agora é só aguardar pelo seu feedback mensal!',
      });
      return;
    }

    this.dialog
      .open(UploadVideoDialogComponent, {
        data: { videoType },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((uploadSuccessfull) => {
        if (uploadSuccessfull) {
          if (videoType == VideoType.NEW_WORK) {
            this.newWorkUploaded = 1;
          } else {
            this.revisionUploaded = 0;
          }
          this.getLiveBoardMessages();
        }
      });
  }

  openAssessmentDialog() {
    if (this.ClassroomAssessmentStatuses != undefined)
      this.dialog.open(AssessmentDialogComponent, {
        data: this.ClassroomAssessmentStatuses,
      });
  }
}

export type ClassroomAssessmentStatus = {
  classroomName: string;
  classroomId: string;
  weeklyAssessmentsComplete: boolean;
};
