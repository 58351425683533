import { Component, inject, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StarsComponent } from '../../components/stars/stars.component';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedRoute } from '@angular/router';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { IColaborador } from '../../models/colaborador.model';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MessageService } from '../../services/message.service';

type assessmentQuestion = {
  id: number;
  question_text: string;
  answer?: number;
};

type assessmentAnswer = {
  question_id: number;
  grade: number;
};

@Component({
  selector: 'app-teacher-assessment',
  standalone: true,
  imports: [
    StarsComponent,
    MatDividerModule,
    ProfilePictureComponent,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './teacher-assessment.component.html',
  styleUrl: './teacher-assessment.component.scss',
})
export class TeacherAssessmentComponent implements OnInit {
  protected apiService = inject(ApiService);
  protected questions: assessmentQuestion[] = [];
  private route = inject(ActivatedRoute);
  private messageService = inject(MessageService);

  protected teacher?: IColaborador;

  assessmentComplete: boolean = true;

  filename = '2024_09_bc36ce33-0036-4f54-8604-8a70759aa968.mp4';

  ngOnInit(): void {
    const teacherId = this.route.snapshot.params['id'];

    this.apiService
      .getByFilters<assessmentQuestion[]>('assessments/teachers/questions', {
        teacherId,
      })
      .subscribe((questions) => {
        this.questions = questions;

        this.assessmentComplete = this.questions.every(
          (q) => q.answer != undefined
        );
      });

    this.apiService
      .get<IColaborador>(`users/${teacherId}`)
      .subscribe((user) => {
        this.teacher = user;
      });
  }

  answers: assessmentAnswer[] = [];

  onRate(answer: assessmentAnswer) {
    const existingAnswer = this.answers.find(
      (a) => a.question_id === answer.question_id
    );

    if (existingAnswer) Object.assign(existingAnswer, answer);
    else this.answers.push(answer);
  }

  onSubmit() {
    if (this.teacher == undefined) {
      return;
    }

    if (this.questions.length !== this.answers.length) {
      this.messageService.alert(
        'Responda todas as  perguntas antes de enviar a avaliação'
      );
      return;
    }

    this.apiService
      .post('assessments/teachers/questions', {
        teacher_id: this.teacher.id,
        answers: this.answers,
      })
      .subscribe((response) => {
        this.messageService.celebrate();
      });
  }
}
