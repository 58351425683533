import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { getShortName } from '../../models/colaborador.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-timesheet-dialog',
  standalone: true,
  imports: [
    MatTableModule,
    MatDialogActions,
    MatDialogContent,
    MatButtonModule,
    MatDialogTitle,
    MatDialogClose,
  ],
  templateUrl: './timesheet-dialog.component.html',
  styleUrl: './timesheet-dialog.component.scss',
})
export class TimesheetDialogComponent {
  readonly timeSheetRecords = inject<timeSheetRecord[]>(MAT_DIALOG_DATA);

  private authService = inject(AuthService);

  displayedColumns: (keyof timeSheetRecord)[] = [
    'date',
    'teacher',
    'classroomName',
    'lastWord',
    'paragraph',
    'dictation',
    'reading',
    'stageId',
  ];

  constructor(){
    if(this.authService.hasRole(this.authService.roles.Professor))
      this.displayedColumns = this.displayedColumns.filter(c => c != 'teacher');
  }

  // getShortName = getShortName;

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getUTCFullYear().toString();

    return `${day}/${month}/${year}`;
  }
}

type timeSheetRecord = {
  date: string;
  teacher: string;
  classroomName: string;
  dictation: number;
  lastWord: string;
  paragraph: number;
  reading: number;
  stageId: number;
};
