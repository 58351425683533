import { Component, inject, OnInit } from '@angular/core';
import { ITurma } from '../../models/turma.model';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../../services/api.service';
import { TurmaService } from '../../services/turma.service';
import { ETurmaStatus } from '../../enums/e-turma-status.enum';
import { IPaginatedResult } from '../../models/paginatedResult.model';
import { IColaborador } from '../../models/colaborador.model';

type option = Record<'id' | 'name', string | number>;

@Component({
  selector: 'app-turma-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './turma-dialog.component.html',
  styleUrl: './turma-dialog.component.scss',
})
export class TurmaDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<TurmaDialogComponent>);

  readonly turma? = inject<ITurma>(MAT_DIALOG_DATA);

  protected apiService = inject(ApiService);

  protected turmaService = inject(TurmaService);

  classroomForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.classroomForm = this.fb.group({
      name: ['', Validators.required],
      unitId: ['', Validators.required],
      formatId: ['', [Validators.required]],
      stageId: ['', [Validators.required]],
      timeslot: ['', [Validators.required]],
      statusId: ['', [Validators.required]],
      teacherId: [''],
    });
  }

  estagios: option[] = [];
  formatos: option[] = [];
  unidades: option[] = [];
  teachers: option[] = [];
  horarios: string[] = Array.from(
    { length: 14 },
    (_, i) => `${(i + 7).toString().padStart(2, '0')}:00h`
  );

  statuses: option[] = [
    { id: ETurmaStatus.EM_FORMAÇÃO, name: 'Em formação' },
    { id: ETurmaStatus.EM_ANDAMENTO, name: 'Em andamento' },
    { id: ETurmaStatus.EM_REVISÃO, name: 'Em revisão' },
    { id: ETurmaStatus.EM_PROVAS, name: 'Em provas' },
    { id: ETurmaStatus.FINALIZADA, name: 'Finalizada' },
  ];

  ngOnInit(): void {
    this.apiService
      .get<option[]>('stages/')
      .subscribe((stages) => (this.estagios = stages));
    this.apiService
      .get<option[]>('classrooms/formats')
      .subscribe((formatos) => (this.formatos = formatos));
    this.apiService
      .get<option[]>('classrooms/school-units')
      .subscribe((unidades) => (this.unidades = unidades));

    this.apiService
      .getByFilters<IPaginatedResult<IColaborador>>('users/', {
        summarized: true,
        pageSize: 100,
      })
      .subscribe((professores) => (this.teachers = professores.items));

    if (this.turma) this.classroomForm.patchValue(this.turma);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close();

    this.turmaService.put({
      id: this.turma?.id,
      ...this.classroomForm.value,
    });
  }
}
