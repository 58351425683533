import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LiveBoardComponent } from '../../components/live-board/live-board.component';
import { StatisticComponent } from '../../components/statistic/statistic.component';
import { ITurma } from '../../models/turma.model';
import { ApiService } from '../../services/api.service';
import { enumToString } from '../../utils/enum-to-string.util';
import {
  ETurmaStatus,
  ETurmaStatusColor,
  getTurmaStatusColor,
} from '../../enums/e-turma-status.enum';
import { MatTableModule } from '@angular/material/table';
import { ChipComponent } from '../../components/chip/chip.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { TurmaService } from '../../services/turma.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { TurmaDialogComponent } from '../../components/turma-dialog/turma-dialog.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Subscription, switchMap } from 'rxjs';
import { NgClass } from '@angular/common';
import { LogomarkComponent } from '../../components/logomark/logomark.component';
import { AuthService } from '../../services/auth.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { TimesheetDialogComponent } from '../../components/timesheet-dialog/timesheet-dialog.component';
import { IDiario } from '../../models/diario.model';
import { TeacherDashboardComponent } from '../../components/teacher-dashboard/teacher-dashboard.component';
import { getShortName } from '../../models/colaborador.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LoadingComponent,
    MatIconModule,
    MatTableModule,
    ChipComponent,
    RouterModule,
    LiveBoardComponent,
    StatisticComponent,
    NotFoundComponent,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
    NgClass,
    LogomarkComponent,
    TeacherDashboardComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  displayedColumns: string[] = [
    'nome',
    'estagio',
    'horario',
    'teacher',
    'unidade',
    'status',
    'actions',
  ];

  constructor(){
    if(this.authService.hasRole(ECargo.Professor))
      this.displayedColumns = this.displayedColumns.map(c=> c == 'teacher'? 'formato': c)
  }

  protected getShortName = getShortName;
  protected enumToString = enumToString;
  protected eTurmaStatus = ETurmaStatus;
  protected eTurmaStatusColor = ETurmaStatusColor;
  protected getTurmaStatusColor = getTurmaStatusColor;
  readonly dialog = inject(MatDialog);
  private cd = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);

  protected apiService = inject(ApiService);
  protected authService = inject(AuthService);
  protected turmaService = inject(TurmaService);

  protected searchFilter?: string;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(switchMap(() => this.loadData()))
      .subscribe(this.turmaService.onLoad.bind(this.turmaService));

    this.searchFilter = this.route.snapshot.queryParams['search'];

    if (!this.routeSubscription) {
      this.routeSubscription = this.route.queryParams.subscribe((params) => {
        this.searchFilter = params['search'];
        this.loadData(params['search']).subscribe(
          this.turmaService.onLoad.bind(this.turmaService)
        );
      });
    }

    this.cd.detectChanges();
  }

  loadData(nameFilter?: string) {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    return this.turmaService.getAll(pageIndex, pageSize, nameFilter);
  }

  edit(turma: ITurma): void {
    this.dialog.open(TurmaDialogComponent, {
      data: turma,
    });
  }

  isTeacher(): boolean {
    return this.authService.hasRole(ECargo.Professor);
  }

  canEdit(): boolean {
    return this.authService.currentUser.roleId != ECargo.Professor;
  }

  private routeSubscription?: Subscription;

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
