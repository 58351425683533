@if(colaboradorService.colaboradores.data.length === 0) {
<div class="h-full flex flex-col gap-5 justify-center items-center">
    <app-logomark [theme]="'dark'" class="md:hidden"></app-logomark>
    <app-loading></app-loading>
</div>
}
@else{
<div class="w-full relative">

    <div class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
        [ngClass]="{'hidden': !colaboradorService.isLoading, 'flex': colaboradorService.isLoading}">
        <app-loading [shape]="'circle'"></app-loading>
    </div>

    <table mat-table [dataSource]="colaboradorService.colaboradores" class="table">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                <span class="max-md:hidden">Nome</span>
                <span class="md:hidden">
                    <mat-icon class="mat-18">work</mat-icon>
                    Colaboradores
                </span>
            </th>
            <td mat-cell *matCellDef="let colaborador">
                <div class="flex flex-row gap-5 items-center">
                    <img [src]="'/assets/people/' + colaborador.gender + '/person-' + colaborador.avatarId + '.jpeg'"
                        alt="Profile Picture" class="w-10 rounded-full">
                    {{colaborador.name}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Email </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                {{colaborador.email}}
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Cargo </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                <div class="flex flex-row gap-2">
                    <chip color="blue">
                        {{colaborador.role}}
                    </chip>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Unidade </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                {{colaborador.unit}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Status </th>
            <td mat-cell *matCellDef="let colaborador" class="max-md:hidden">
                @if(colaborador.active) {
                <chip color="green">
                    Ativo
                </chip>
                }
                @else {
                <chip color="red">
                    Inativo
                </chip>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let colaborador">
                <button mat-icon-button class="!overflow-hidden !rounded-full float-right"
                    [matMenuTriggerFor]="options">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #options="matMenu">
                    <button mat-menu-item (click)="edit(colaborador)">
                        <mat-icon>edit</mat-icon>
                        <span class="font-normal text-light">Editar</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>
}

<div [hidden]="colaboradorService.colaboradores.data.length === 0" class="mt-5">
    <mat-paginator [pageSize]="8" [hidePageSize]="true" [pageIndex]="colaboradorService.pageIndex"
        [length]="colaboradorService.totalItems" [showFirstLastButtons]="true">
    </mat-paginator>
</div>