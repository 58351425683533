import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from './api.service';
import { tap } from 'rxjs';

export type notification = {
  title: string;
  message: string;
  timestamp: string;
  triggeredBy: string;
  read: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private apiService = inject(ApiService);


  public notifications = signal<notification[]>([]);


  getAll() {
    return this.apiService
      .get<notification[]>('/notifications/', undefined, false)
      .pipe(
        tap((notifications) => {
          this.notifications.set(notifications);
        })
      );
  }
}
