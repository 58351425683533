import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NotificationService } from '../../services/notification.service';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuItem } from '@angular/material/menu';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [MatIconModule, MatMenuItem],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent {
  protected notificationService = inject(NotificationService);
}