import {
  Component,
  inject,
} from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { ChipComponent } from '../../components/chip/chip.component';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BasePaginatedComponent } from '../../abstracts/paginated-view.abstract';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MobileQueryService } from '../../services/mobile-query.service';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';

export enum ERiskClassification {
  BAIXO = 1,
  MEDIO = 2,
  ALTO = 3,
}

export type studentRiskMap = {
  average_attendance: number;
  average_performance: number;
  name: string;
  risk_classification: ERiskClassification;
};

@Component({
  selector: 'app-mapa-de-risco',
  standalone: true,
  imports: [
    MatTableModule,
    ChipComponent,
    ProfilePictureComponent,
    NgTemplateOutlet,
    NgClass,
    MatPaginatorModule,
    LoadingComponent,
    LoadingScreenComponent
  ],
  templateUrl: './mapa-de-risco.component.html',
  styleUrl: './mapa-de-risco.component.scss',
})
export class MapaDeRiscoComponent extends BasePaginatedComponent {

  protected ERiskClassification = ERiskClassification;
  protected mobileQueryService = inject(MobileQueryService)

  desktopColumns: string[] = [
    'name',
    'school',
    'classroom',
    'attendance',
    'performance',
    'risk',
  ];

  mobileColumns: string[] = [
    'name',
    'risk',
  ]

}