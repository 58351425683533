@if(turma){
<h2 mat-dialog-title>Editar Turma</h2>
} @else {
<h2 mat-dialog-title>Nova Turma</h2>
}
<mat-dialog-content>
    <form [formGroup]="classroomForm" class="grid grid-cols-2 gap-x-4">

        <mat-form-field class="col-span-2">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Unidade</mat-label>
            <mat-select formControlName="unitId">
                @for (unidade of unidades; track unidade) {
                <mat-option [value]="unidade.id">{{unidade.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Estágio</mat-label>
            <mat-select formControlName="stageId">
                @for (estagio of estagios; track estagio) {
                <mat-option [value]="estagio.id">{{estagio.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Formato</mat-label>
            <mat-select formControlName="formatId">
                @for (estagio of formatos; track estagio) {
                <mat-option [value]="estagio.id">{{estagio.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Horário</mat-label>
            <mat-select formControlName="timeslot">
                @for (horario of horarios; track horario) {
                <mat-option [value]="horario">{{horario}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select formControlName="statusId">
                @for (status of statuses; track status) {
                <mat-option [value]="status.id">{{status.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Professor</mat-label>
            <mat-select formControlName="teacherId">
                @for (teacher of teachers; track teacher) {
                @let splitName = teacher.name.toString().split(' ');
                <mat-option [value]="teacher.id">{{splitName[0]}} {{splitName[1]}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-flat-button (click)="onSubmit()" [disabled]="classroomForm.invalid || apiService.isLoading">
        {{ turma ? 'Salvar' : 'Adicionar' }}
    </button>
</mat-dialog-actions>