@if(!turma) {
<div class="h-full flex flex-col justify-center items-center gap-5">
  <app-logomark [theme]="'dark'" class="md:hidden"></app-logomark>
  <app-loading></app-loading>
</div>
}
@else {

<div class="flex flex-col md:flex-row mb-4 gap-4">

  <div class="card p-4 w-full md:w-1/3 bg-right-bottom bg-contain bg-no-repeat
    flex flex-row bg-[url(/assets/triangle-light.png)]">
    <div class="w-2/3 flex flex-col gap-2 justify-between">

      <div class="flex flex-col gap-2">

        <h6>{{turma.name}}</h6>
        
        <p class="text-sm capitalize text-light">
          {{turma.format}} ({{turma.timeslot}})
        </p>

        <h6 style="font-size: 1rem;" class="text-light">
          <mat-icon class="align-sub">place_on</mat-icon>
          {{turma.unit}}
        </h6>

      </div>

      <chip [color]="getTurmaStatusColor(turma.statusId)">
        {{enumToString(eTurmaStatus, turma.statusId)}}
      </chip>

    </div>
    <div class="w-1/3 flex items-end justify-center">
      @if (turma.stageId) {
        <img [src]="'/assets/book-cover-' + turma.stageId + '.jpg'" alt="Book cover" class="w-3/4 shadow-gray-400 shadow-md rounded">
      }
      @else {
        <img [src]="'/assets/big-ben.png'" alt="Book cover" class="w-4/5">
      }
    </div>
  </div>

  <div class="card p-4 w-full md:w-2/3 flex flex-col justify-between">

    <h6 class="!mb-0">Estatísticas</h6>

    <app-live-board class="h-20 md:h-full">
      <p class="text-sm !mt-3">
        <span class="font-medium">Os dados aparecerão aqui</span> 🤓
        <span class="text-light">assim que houver informações suficientes</span>
      </p>
      <p class="text-sm !mt-3">
        <span class="font-medium">A turma está pronta para começar!</span> 🚀
        <span class="text-light">Fique atento às atualizações</span>
      </p>
    </app-live-board>

    <div class="flex flex-col gap-y-6 md:flex-row w-full justify-between">
        <app-statistic color="primary" label="Frequência" icon="trending_up">
          @if(averageAttendance != undefined){
            <span class="slide-in">
              {{averageAttendance}}%
            </span>
          }
        </app-statistic>
        <app-statistic color="green" label="Progresso" icon="hiking">
          @if(classroomProgress != undefined){
            <span class="slide-in">
              {{classroomProgress}}%
            </span>
          }
        </app-statistic>
        <app-statistic color="yellow" label="Avaliação" icon="speed">
          @if(assessmentPercentage != undefined){
            <span class="slide-in">
              {{assessmentPercentage}}%
            </span>
          }
        </app-statistic>
        <app-statistic color="purple" label="Alunos" icon="wc">{{turma.students.length}}</app-statistic>
    </div>
  </div>

</div>

<div class="flex flex-col gap-4">

  <app-diario class="w-full"
    [stageId]="turma.stageId"
   [classroomId]="classroomId">
  </app-diario>

  <app-students-table [(students)]="turma.students" [attendances]="attendance" (onGetAssessmentPercentage)="assessmentPercentage = $event"></app-students-table>

</div>

  <div class="flex flex-row gap-5">

    <!-- <div class="card w-1/3 p-4">
      <h6 class="!mb-10">Presença</h6>
      <app-bar-chart></app-bar-chart>
    </div> -->

</div>

}