import {
  AfterViewInit,
  Component,
  inject,
  ViewChild,
} from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { IColaborador } from '../../models/colaborador.model';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ChipComponent } from '../../components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ColaboradorService } from '../../services/colaborador.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { MatDialog } from '@angular/material/dialog';
import { ColaboradorDialogComponent } from '../../components/colaborador-dialog/colaborador-dialog.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { switchMap } from 'rxjs';
import { NgClass } from '@angular/common';
import { LogomarkComponent } from '../../components/logomark/logomark.component';

@Component({
  selector: 'app-colaboradores',
  standalone: true,
  imports: [
    MatTableModule,
    LoadingComponent,
    ChipComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatPaginatorModule,
    NgClass,
    LogomarkComponent
  ],
  templateUrl: './colaboradores.component.html',
  styleUrl: './colaboradores.component.scss',
})
export class ColaboradoresComponent implements AfterViewInit {
  readonly dialog = inject(MatDialog);

  displayedColumns: string[] = ['name', 'role', 'unit', 'email', 'status', 'actions'];
  eCargo = ECargo;

  protected colaboradorService = inject(ColaboradorService);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(switchMap(() => this.loadData()))
      .subscribe(this.colaboradorService.onLoad.bind(this.colaboradorService));

    if(this.colaboradorService.colaboradores.data.length === 0)
      this.loadData().subscribe(this.colaboradorService.onLoad.bind(this.colaboradorService));
  }

  loadData() {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    return this.colaboradorService.getAll(pageIndex, pageSize);
  }

  edit(colaborador: IColaborador) {
    this.dialog.open(ColaboradorDialogComponent, {
      data: colaborador,
    });
  }
}