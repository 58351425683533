import { Component, input, Input, OnInit, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { range } from '../../utils/range.util';
import { EGrade } from '../../enums/e-grade.enum';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-stars',
  standalone: true,
  imports: [MatIconModule, NgClass],
  templateUrl: './stars.component.html',
  styleUrl: './stars.component.scss',
})
export class StarsComponent implements OnInit {
  readonly = input<boolean>(false);
  grade = input<number>();

  value: number = 0;

  change = output<EGrade>();

  range: number[] = [];

  ngOnInit(): void {
    let grade = this.grade() ?? 0;

    if(grade>3) grade = Math.ceil(grade/(100/3))

    this.value = grade;
    this.range = range(1, Object.keys(EGrade).length / 2);
  }

  changeGrade(value: number) {
    if (this.readonly()) return;

    this.value = value;
    // this.grade = getEnumValue(EGrade, value);
    this.change.emit(value);
  }
}
